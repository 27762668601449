import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Logo from "../../static/images/logo/logo-only.svg"

const NotFoundPage = () => {
    return (
        <Layout page="404">
            <Seo title="404: Not found" 
                description="「待ち時間0秒」のハイパフォーマンスなウェブサイト制作専門。Jamstack、ヘッドレスCMSなどの最新テクノロジーで実現したストレスフリーなウェブ体験で、 SEO、アクセス数、サイト回遊率、CV率を高め、あなたのウェブサイトを成功に導きます。"
            />
            <div className="container 404" style={{textAlign: "center", margin: "140px auto 100px auto"}}>
                    <Link to="/" title="monotein">
                        <img alt="Logo" src={Logo} style={{width: "80px"}}/>
                    </Link>
                    <h3 style={{margin: "24px 0 30px" }}>このURLにはページがありません。</h3>
                    <div><Link to="/">最初のページに戻る</Link></div>
            </div>
        </Layout>
    )
}

export default NotFoundPage
